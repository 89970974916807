import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import loadFunctions from '../../../components/firebase-functions';
import ElevateLogo from '../../../images/elevate/elevateLogo.png';
import * as Yup from 'yup';
import './styles.css';

Modal.setAppElement('#___gatsby'); // Set the root element for accessibility

export const FormModal = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
    });

    const handleSubmit = async values => {
        setLoading(true);
        try {
            console.log(values);
            const functions = await loadFunctions();
            await functions.httpsCallable('subscribeMailChimpUser')({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                tag: 'Elevate',
            });
            setSubmitted(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Modal
                isOpen={true}
                contentLabel="Elevate Sign Up Form"
                className="elevate-modal"
                overlayClassName="overlay"
            >
                <div className="elevate-header">
                    <img src={ElevateLogo} alt="Elevate Logo" className="elevate-logo" />
                    <h1 className="elevate-title">ELEVATE</h1>
                </div>
                {!submitted ? (
                    <h2 className="form-title">
                        GET ON THE ELEVATE ACCESS LIST FOR THE SPECIAL LAUNCH PRICE 💪
                    </h2>
                ) : (
                    <h2 className="congrats-title">
                        CONGRATS. YOU ARE ON THE ELEVATE ACCESS LIST!
                    </h2>
                )}

                {!submitted && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div>
                                    <label htmlFor="firstName">First Name:</label>
                                    <Field type="text" name="firstName" />
                                    <ErrorMessage
                                        name="firstName"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="lastName">Last Name:</label>
                                    <Field type="text" name="lastName" />
                                    <ErrorMessage
                                        name="lastName"
                                        component="div"
                                        className="error"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="email">Email:</label>
                                    <Field type="email" name="email" />
                                    <ErrorMessage name="email" component="div" className="error" />
                                </div>

                                <div className="btn">
                                    <button
                                        className="elevate-form-submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {loading ? 'JOINING...' : 'JOIN ACCESS LIST'}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </Modal>
        </div>
    );
};
